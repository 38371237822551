import {useEffect, useState} from "react";
import $ from 'jquery';
import {Link} from "react-router-dom";
import info_api from "../api/info";

import left_arrow from "../assets/left-arrow.svg";
import right_arrow from "../assets/right-arrow.svg";
import loading_wheel from "../assets/loading_wheel.png";
import GenericDialog from "../components/GenericDialog";

const InfoOfferCards = () => {
    const pageSize = 10;

    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalArticles, setTotalArticles] = useState(0);
    const [infoOfferCards, setInfoOfferCards] = useState([])
    const [pageLinks, setPageLinks] = useState([]);

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const dialogButtons = [{"text":"OK", "id": "1"}, {"text":"Reload Page", "id": "2"}];

    const GetInfoOfferCards = () => {
        setLoading(true)
        let response = info_api.getInfoOfferCards();
        response.then(
            response => {
                setInfoOfferCards(response.data.InfoOfferCards);
                setLoading(false)
            }
        ).catch((err) => {
            setLoading(false)
            setError(true)
            setErrorMessage(err.message)
        })
    }

    const handleDialogResult = (ev) => {
        if (ev === "1") {
            setError(false)
            setErrorMessage(undefined)
        }
        else if (ev === "2") {
            window.location.reload();
        }

    }

    const GetPages = () => {
        if (!infoOfferCards) return;
        setTotalArticles(infoOfferCards.length);
        let pgs = infoOfferCards.length / pageSize
        let pageArray = [];
        for (let i=0;i<pgs;i++){
            pageArray.push(i+1);
        }
        setPages(pageArray);
        GetCurrentPageLinks(currentPage);
    }

    const handleFirstClick = () => {
        if (!currentPage) return;
        if (currentPage > 1){
            let pg = 1;
            setCurrentPage(pg);
            GetCurrentPageLinks(pg);
        }
    }


    const handleLastClick = () => {
        if (!currentPage) return;
        if (currentPage >= 1){
            let pg = pages.length;
            if (currentPage-1 === pg){
                return;
            }
            setCurrentPage(pg);
            GetCurrentPageLinks(pg);
        }
    }

    const handlePrevClick = () => {
        if (!currentPage) return;
        if (currentPage > 1){
            let pg = currentPage;
            pg --;
            setCurrentPage(pg);
            GetCurrentPageLinks(pg);
        }
    }

    const handleNextClick = () => {
        if (!currentPage) return;
        if (currentPage < pages.length){
            let pg = currentPage;
            pg ++;
            setCurrentPage(pg);
            GetCurrentPageLinks(pg);
        }
    }

    const GetCurrentPageLinks = (page) => {
        if (!infoOfferCards) return;
        let currentPageArticles = infoOfferCards.slice(((page-1)*pageSize), pageSize * page)
        setPageLinks(currentPageArticles)
    }

    const handleNumberClick = (ev) => {
        if (!currentPage) return;
        if (!ev.target.id && ev.target.id !== 0) return;
        let pg = parseInt(ev.target.id)
        setCurrentPage(pg);
        GetCurrentPageLinks(pg);
        window.scrollTo(0,500);
    }

    useEffect(() => {
        GetInfoOfferCards()
    }, []);

    useEffect( () => {
        GetPages()
    }, [infoOfferCards])


    return (
        <>
            <div className="main">
                <div className="page-title">
                    <div>Info Offer Cards:</div>
                </div>
                <div className="current-articles-subsection">
                    <div>Current Info Offer Cards:</div>
                    <div className="articles-form">
                        <table className="article-table">
                            <tbody>
                                <tr className="table-headings">
                                    <th className="table-name">Info Offer Card ID</th>
                                    <th className="table-description">Description</th>
                                    <th className="table-name">Button Text</th>
                                    <th className="table-description">URL</th>
                                    <th className="table-edit">Edit</th>
                                </tr>
                                <tr>
                                    <img className={loading ? "loading-wheel" : "loading-wheel-hidden"}
                                         src={loading_wheel}  />
                                </tr>
                                {pageLinks && pageLinks.map((offer, idx) => {
                                    return (
                                        <tr className="table-element">
                                            <td className="table-name-element">{offer.Id}</td>
                                            <td className="table-description-element">{offer.Description}</td>
                                            <td className="table-name-element">{offer.ButtonText}</td>
                                            <td className="table-description-element">{offer.URL}</td>
                                            <td className="table-edit-element">
                                                <Link className="table-edit-link" to={"/info-offer-card/" + offer.Id}>
                                                    Edit
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="table-buttons">
                            <div className="table-navigation">
                                <div className="table-navigation-contents">
                                    <div className="table-prev">
                                        <button onClick={handleFirstClick}><img src={left_arrow}/> First</button>
                                        <button onClick={handlePrevClick}><img src={left_arrow}/> Prev</button>
                                    </div>
                                    <div className="table-pages">
                                        {pages && pages.map((page, idx) => {
                                            return (
                                                <>
                                                    <div className={currentPage === page ? "page active link" :
                                                        (page>currentPage + 3 ? "pagehidden" : (page<currentPage - 3 ? "pagehidden" : "page link"))}
                                                         onClick={handleNumberClick} id={page}>{page}
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                    <div className="table-next">
                                        <button onClick={handleNextClick}>Next <img src={right_arrow}/> </button>
                                        <button onClick={handleLastClick}>Last <img src={right_arrow}/> </button>
                                    </div>
                                </div>
                                <div className="articles-showing">
                                    Showing {((currentPage-1)*pageSize)+1}-{Math.min((currentPage*pageSize),totalArticles)} out of {totalArticles}
                                </div>
                            </div>
                        </div>
                        <div className="create-new-container">
                            <Link className="create-new-button" to={"/info-offer-card/new"}>
                                Add an Info Offer card
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {error ?
                <GenericDialog title="Affiliate Links Page Error" text={errorMessage}
                               buttons={dialogButtons} result={handleDialogResult.bind(this)}
                /> : null }
        </>
    )
}

export default InfoOfferCards