import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import './utils/Loader';
import AppLayout from "./containers/AppLayout";
import {useEffect} from "react";

import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import api from "./api/http"
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

function App({ signOut, user }) {


    useEffect(() => {
        api.defaults.headers.Authorization = 'Bearer ' + user.signInUserSession.accessToken.jwtToken
    }, [user]);


    return (
      <div className="user-login-header">
          <div className="user-details">
              <h1>Logged In As: {user.username}</h1>
              <button onClick={signOut}>Sign out</button>
          </div>

          <Router>
            <AppLayout />
          </Router>
      </div>
  );
}

export default withAuthenticator(App);
