
const ArticleButtons = (props) => {
    const article_id = props.article_id
    const CreateArticle = props.create
    const DeleteArticle = props.delete
    const UpdateArticle = props.update

    return (
        <div className="form-button-container">
            { article_id === "new" ?
                <input type="submit" className="endFormButton" value="Create" onClick={CreateArticle}/>
                :
                <div className="article-update-delete-buttons-containers">
                    <div className="update-button-container">
                        <input className="update-button" type="button" value="Update" onClick={UpdateArticle}/>
                    </div>
                    <div className="update-button-container">
                        <input className="delete-button" type="button" value="Delete" onClick={DeleteArticle}/>
                    </div>
                </div>}
        </div>
    )

}

export default ArticleButtons