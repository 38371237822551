const IDDisplay = (props) => {
    const id = props.id
    const word = props.word

    return (
        <div className="article-id-section">
            <div className="col-25 article-label">
                <label className="articleID-label"> {word}: </label>
            </div>
            <div className="col-60 article-input-box">
                <label>{id === "new" ? "-" : id}</label>
            </div>
        </div>
    )
}

export default IDDisplay