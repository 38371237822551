
const Description = (props) => {
    const description = props.description
    const UpdateDescription = props.setShow

    return (
        <div className="article-description-section">
            <div className="col-25 article-label">
                <label className="description-input-label">Description:</label>
            </div>
            <div className="col-60 article-input-box">
                <textarea className="description-input" id="description-input" value={description}
                          onChange={(e) => UpdateDescription(e.target.value)}/>
            </div>
        </div>
    )
}

export default Description

