import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import info_api from "../api/info";
import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import ArticleButtons from "../containers/ArticleButtons";
import IDDisplay from "../containers/IDDIsplay";
import InfoArticleSelector from "../containers/InfoArticleSelector";
import GenericDialog from "../components/GenericDialog";
import loading_wheel from "../assets/loading_wheel.png";
import Description from "../containers/Description";
import ArticleDescription from "../containers/Description";
import ButtonText from "../containers/ButtonText";
import LinkURL from "../containers/LinkURL";
import affiliate_api from "../api/affiliate";

const CategoryPage = () => {
    let params = useParams();
    const navigate = useNavigate();

    var _id = "new"
    _id = params.storyId

    const [buttonText, setButtonText] = useState("")
    const [description, setDescription] = useState("")
    const [articleId, setArticleID] = useState()
    const [url, setURL] = useState("")

    const [infoArticles, setInfoArticles] = useState()

    const [error, setError] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const dialogButtons = [{"text":"OK", "id": "1"}];

    const GetInfoOfferCard = () => {
        if (!_id) return;
        if (_id==="new") return;
        console.log(_id)
        let response = info_api.getInfoOfferCard(_id);
        response.then(
            response => {
                console.log(response.data)
                let data = response.data.InfoOfferCards[0]
                console.log(data)
                setButtonText(data.ButtonText)
                setDescription(data.Description)
                setArticleID(data.ArticleID)
                setURL(data.URL)
            }
        )
    }

    const GetAllInfoCards = () => {
        if (!_id) return;
        let response = info_api.getInfoArticles();
        response.then(
            response => {
                console.log(response.data)
                let data = response.data.Articles
                if (data.length === 0) return;
                setInfoArticles(data)
                if (_id === "new"){
                    setArticleID(data[0].Id)
                }
            }
        )
    }

    const handleDialogResult = (ev) => {
        if (ev === "1") {
            setError(false)
            setErrorMessage(undefined)
            window.location.reload();
        }
    }

    useEffect( () => {
        GetInfoOfferCard()
        GetAllInfoCards()
    }, [_id]);


    const info_offer_update = () => {
        let response = info_api.update_info_offer_card(_id, description, buttonText, url, articleId);
        response.then(
            response => {
                let confirmation = window.confirm("Updated Info Offer Card ID: " + response.data.InfoOfferCardID)
                if (confirmation===true || confirmation===false) {
                    link_offer_cards()
                }
            }
        )
    }

    const info_offer_create = () => {
        let response = info_api.create_info_offer_card(description, buttonText, url, articleId);
        response.then(
            response => {
                var created_id = response.data.InfoOfferCardID
                alert("Created Info Offer Card: " + created_id)
                link_to_offer_card(created_id)
            }
        )
    }

    const info_offer_delete = () => {
        let response = window.confirm("Are you sure you want to delete the Info Offer Card?")
        if (response===true){
            let response = info_api.delete_info_offer_card(_id);
            response.then(
                response => {
                let confirmation = window.confirm("Deleted Info Offer Card: " + _id)
                if (confirmation===true || confirmation===false) {
                    link_offer_cards()
                }
            })
        }
    }

    const link_to_offer_card = useCallback((created_id) => navigate('/info-offer-card/'+created_id, {replace: true}), [navigate]);
    const link_offer_cards = useCallback(() => navigate('/info-offer-cards', {replace: true}), [navigate]);

    const UpdateDescription = (description) => {
        if (!_id) return;
        setDescription(description)
    }
    const UpdateButtonText = (text) => {
        if (!_id) return;
        setButtonText(text)
    }
    const UpdateLinkURL = (text) => {
        if (!_id) return;
        setURL(text)
    }
    const UpdateInfoArticle = (article) => {
        if (!_id) return;
        setArticleID(article)
    }


    return (
        <>
            <div className="main">
                <div className="page-title">
                    <div>{_id === "new" ? "New Info Offer Card" : "Edit Info Offer Card" } </div>
                </div>
                <div className="article-form">
                    <IDDisplay word={"Info Offer Card ID"} id={_id} />
                    <ArticleDescription description={description} setShow={UpdateDescription.bind(this)}/>
                    <ButtonText buttonText={buttonText} setButtonText={UpdateButtonText.bind(this)} />
                    <LinkURL linkURL={url} setLinkURL={UpdateLinkURL.bind(this)} />
                    <InfoArticleSelector selected={articleId} options={infoArticles} word="Info Article"
                              setSelected={UpdateInfoArticle.bind(this)} />


                </div>

                <div className="article-form-buttons">
                    <ArticleButtons article_id={_id}
                                    create={info_offer_create.bind(this)}
                                    update={info_offer_update.bind(this)}
                                    delete={info_offer_delete.bind(this)}/>
                </div>

            </div>
            <div className="footer" />
            {error ?
                <GenericDialog title="Info Offer Card Page" text={errorMessage}
                               buttons={dialogButtons} result={handleDialogResult.bind(this)}
                /> : null }
        </>
    )
}

export default CategoryPage;