import http from "./http";
import chapter from "../pages/Chapter";


const get_affiliate_links = () => http.get("/protected/referral_links")
const get_affiliate_link = (_id) => http.get("/protected/referral_links/" + _id)
const delete_affiliate_link = (_id) => http.delete("/protected/referral_links/" + _id)

const create_affiliate_links = (coinmarketcap_id, link) => http.post("/protected/referral_links", {
        "coinmarketcap_id": coinmarketcap_id,
        "link": link,
    }
)

const update_affiliate_links = (_id, coinmarketcap_id, link, published) => http.put("/protected/referral_links/" + _id, {
        "coinmarketcap_id": coinmarketcap_id,
        "link": link,
        "published": published
    }
)

const get_exchanges = () => http.get("/protected/get_exchanges")

const publish = (content_id) => http.post("/protected/publish/referral_link/" + content_id)
const unPublish = (content_id) => http.delete("/protected/publish/referral_link/" + content_id)

export default {
    get_affiliate_links,
    get_affiliate_link,
    delete_affiliate_link,
    create_affiliate_links,
    update_affiliate_links,
    get_exchanges,
    publish,
    unPublish
}