
const ButtonText = (props) => {
    const buttonText = props.buttonText
    const updateButtonText = props.setButtonText

    return (
        <div className="article-title-section">
            <div className="col-25 article-label">
                <label className="name-input-label">Button Text:</label>
            </div>
            <div className="col-60 article-input-box">
                <textarea className="name-input" id="title-input" value={buttonText}
                       onChange={(e) => updateButtonText(e.target.value)}/>
            </div>
        </div>
    )
}

export default ButtonText