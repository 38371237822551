const InfoArticleSelector = (props) => {
    const selected = props.selected
    const options = props.options
    const word = props.word
    const UpdateSelected = props.setSelected

    return (
        <div className="article-id-section">
            <div className="col-25 article-label">
                <label className="articleID-label"> {word}: </label>
            </div>
            <div className="col-60 article-input-box">
                <select className={"selector-" + word} id={word} onChange={(e) =>
                {
                    UpdateSelected(e.target.value);
                }}>
                    {options && options.map((option, idx) => {
                        return (
                            <option value={option.Id} selected={selected === option.Id ? "selected" : null}>{option.Title}</option>
                        )
                    })}
                </select>
            </div>
        </div>
    )
}

export default InfoArticleSelector