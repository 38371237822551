import {useEffect, useState} from "react";
import $ from 'jquery';
import {Link} from "react-router-dom";
import chapter_api from "../api/chapter";

import left_arrow from "../assets/left-arrow.svg";
import right_arrow from "../assets/right-arrow.svg";
import loading_wheel from "../assets/loading_wheel.png";
import GenericDialog from "../components/GenericDialog";


const Chapter = () => {
    const pageSize = 10;

    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalArticles, setTotalArticles] = useState(0);

    const [infoArticles, setInfoArticles] = useState([])
    const [pageArticles, setPageArticles] = useState([]);

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const dialogButtons = [{"text":"OK", "id": "1"}, {"text":"Reload Page", "id": "2"}];

    const GetArticles = () => {
        setLoading(true)
        let response = chapter_api.getChapters();
        response.then(
            response => {
                setInfoArticles(response.data.Chapters);
                setLoading(false)
            }
        ).catch((err) => {
            setLoading(false)
            setError(true)
            setErrorMessage(err.message)
        })
    }

    const handleDialogResult = (ev) => {
        if (ev === "1") {
            setError(false)
            setErrorMessage(undefined)
        }
        else if (ev === "2") {
            window.location.reload();
        }

    }

    const GetPages = () => {
        if (!infoArticles) return;
        setTotalArticles(infoArticles.length);
        let pgs = infoArticles.length / pageSize
        let pageArray = [];
        for (let i=0;i<pgs;i++){
            pageArray.push(i+1);
        }
        setPages(pageArray);
        GetCurrentPageChapters(currentPage);
    }

    const handleFirstClick = () => {
        if (!currentPage) return;
        if (currentPage > 1){
            let pg = 1;
            setCurrentPage(pg);
            GetCurrentPageChapters(pg);
        }
    }

    const handleLastClick = () => {
        if (!currentPage) return;
        if (currentPage >= 1){
            let pg = pages.length;
            if (currentPage-1 === pg){
                return;
            }
            setCurrentPage(pg);
            GetCurrentPageChapters(pg);
        }
    }

    const handlePrevClick = () => {
        if (!currentPage) return;
        if (currentPage > 1){
            let pg = currentPage;
            pg --;
            setCurrentPage(pg);
            GetCurrentPageChapters(pg);
        }
    }

    const handleNextClick = () => {
        if (!currentPage) return;
        if (currentPage < pages.length){
            let pg = currentPage;
            pg ++;
            setCurrentPage(pg);
            GetCurrentPageChapters(pg);
        }
    }

    const GetCurrentPageChapters = (page) => {
        if (!infoArticles) return;
        let currentPageArticles = infoArticles.slice(((page-1)*pageSize), pageSize * page)
        setPageArticles(currentPageArticles)
    }

    const UpdateSelected = () => {
        for ( let x; x < pageSize; x++) {
            $('input[name=x]:checked').each(function() {
                console.log(this);
            })
        }
    }

    const handleNumberClick = (ev) => {
        if (!currentPage) return;
        if (!ev.target.id && ev.target.id !== 0) return;
        let pg = parseInt(ev.target.id)
        setCurrentPage(pg);
        GetCurrentPageChapters(pg);
        window.scrollTo(0,500);
    }

    const editNewsArticle = () => {
        UpdateSelected();
    }

    useEffect(() => {
        GetArticles()
    }, []);

    useEffect( () => {
        GetPages()
    }, [infoArticles])

    return (
        <>
            <div className="main">
                <div className="page-title">
                    <div>Chapters:</div>
                </div>
                <div className="current-articles-subsection">
                    <div>Current Chapters:</div>
                    <div className="articles-form">
                        <table className="article-table">
                            <tbody>
                                <tr className="table-headings">
                                    <th className="table-id">Chapter ID</th>
                                    <th className="table-title">Title</th>
                                    <th className="table-type">Module</th>
                                    <th className="table-display-number">Number</th>
                                    <td className="table-author">Author</td>
                                    <th className="table-difficulty">Difficulty</th>
                                    <th className="table-approved">Approved</th>
                                    <th className="table-approved">Published</th>
                                    <th className="table-select">Edit</th>
                                </tr>
                                <tr>
                                    <img className={loading ? "loading-wheel" : "loading-wheel-hidden"}
                                         src={loading_wheel}  />
                                </tr>
                                {pageArticles && pageArticles.map((article, idx) => {
                                    return (
                                        <tr className="table-element">
                                            <td className="table-id-element">{article.id}</td>
                                            <td className="table-title-element">{article.title}</td>
                                            <td className="table-type-element">{article.module}</td>
                                            <td className="table-display-number-element">{article.number}</td>
                                            <td className="table-author-element">{article.author}</td>
                                            <td className="table-difficulty-element">{article.difficulty}</td>
                                            <td className={article.approved === 1
                                                ? "table-approved-element approved"
                                                : "table-approved-element"}>
                                                {article.approved === 1 ? "Approved" : "Not Approved"}
                                            </td>
                                            <td className={article.published === 1
                                                ? "table-approved-element approved"
                                                : "table-approved-element"}>
                                                {article.published === 1 ? "Published" : "Not Published"}
                                            </td>
                                            <td className="table-edit-element">
                                                <Link className="table-edit-link" to={"/chapter/" + article.id}>
                                                    Edit
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="table-buttons">
                            <div className="table-navigation">
                                <div className="table-navigation-contents">
                                    <div className="table-prev">
                                        <button onClick={handleFirstClick}><img src={left_arrow}/> First</button>
                                        <button onClick={handlePrevClick}><img src={left_arrow}/> Prev</button>
                                    </div>
                                    <div className="table-pages">
                                        {pages && pages.map((page) => {
                                            return (
                                                <>
                                                    <div className={currentPage === page ? "page active link" :
                                                        (page>currentPage + 3 ? "pagehidden" : (page<currentPage - 3 ? "pagehidden" : "page link"))}
                                                         onClick={handleNumberClick} id={page}>{page}
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                    <div className="table-next">
                                        <button onClick={handleNextClick}>Next <img src={right_arrow}/> </button>
                                        <button onClick={handleLastClick}>Last <img src={right_arrow}/> </button>
                                    </div>
                                </div>
                                <div className="articles-showing">
                                    Showing {((currentPage-1)*pageSize)+1}-{Math.min((currentPage*pageSize),totalArticles)} out of {totalArticles}
                                </div>
                            </div>
                        </div>
                        <div className="create-new-container">
                            <Link className="create-new-button" to={"/chapter/new"}>
                                Create a Chapter
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {error ?
                <GenericDialog title="Chapter Page Error" text={errorMessage}
                               buttons={dialogButtons} result={handleDialogResult.bind(this)}
                /> : null }
        </>
    )
}

export default Chapter