import http from "./http.js"


const getInfoArticles = () => http.get("/protected/cms_info_article");
const getInfoArticle = (num) => http.get("/protected/cms_info_article/" + num);

const getInfoOfferCards = () => http.get("/protected/cms_info_offer_cards");
const getInfoOfferCard = (card_card) => http.get("/protected/cms_info_offer_cards/" + card_card);

const update_info_offer_card = (offer_id, description, button_text, url, article_id) => http.put("/protected/info_offer_cards/" + offer_id, {
    "Description": description,
    "ButtonText": button_text,
    "URL": url,
    "ArticleID": parseInt(article_id)
})

const create_info_offer_card = (description, button_text, url, article_id) => http.post("/protected/info_offer_cards" , {
    "Description": description,
    "ButtonText": button_text,
    "URL": url,
    "ArticleID": parseInt(article_id)
})

const delete_info_offer_card = (offer_id) => http.delete("/protected/info_offer_cards/" + offer_id)


const deleteInfoArticle = (num) => http.delete("/protected/info_article/" + num + "/True");
const deleteInfoBody = (body_id) => http.delete("/protected/info_bodies/"+ body_id)

const postInfoArticle = (title, description, thumbnail_link, hero_link, type, classification, author, info_page, number,
                         article_link)=> http.post("/protected/info_article", {
    "Title": title,
    "Description": description,
    "ThumbnailImage": thumbnail_link,
    "HeroImage": hero_link,
    "Type": type,
    "Classification": classification,
    "Author": author,
    "InfoPage": info_page,
    "Number": number,
    "ArticleLink": article_link
})

const updateInfoArticle = (article_id, title, description, thumbnail_link, hero_link, type, classification, author,
                           info_page, approved, number, article_link)=> http.put("/protected/info_article/" + article_id, {
    "Title": title,
    "Description": description,
    "ThumbnailImage": thumbnail_link,
    "HeroImage": hero_link,
    "Type": type,
    "Classification": classification,
    "Author": author,
    "InfoPage": info_page,
    "Approved": approved,
    "Number": number,
    "ArticleLink": article_link
})

const postStartingInfoArticle = (title, description, thumbnail_link, hero_link, type, classification, author,
                                 card_title, card_description, info_page, number, article_link)=> http.post("/protected/info_starting_article", {
    "Title": title,
    "Description": description,
    "ThumbnailImage": thumbnail_link,
    "HeroImage": hero_link,
    "Type": type,
    "Classification": classification,
    "Author": author,
    "CardTitle": card_title,
    "CardDescription": card_description,
    "InfoPage": info_page,
    "Number": number,
    "ArticleLink": article_link
})

const updateStartingInfoArticle = (article_id, title, description, thumbnail_link, hero_link, type, classification,
                                   author, card_title, card_description, info_page, approved, number, article_link)=> http.put("/protected/info_starting_article/" + article_id, {
    "Title": title,
    "Description": description,
    "ThumbnailImage": thumbnail_link,
    "HeroImage": hero_link,
    "Type": type,
    "Classification": classification,
    "Author": author,
    "CardTitle": card_title,
    "CardDescription": card_description,
    "InfoPage": info_page,
    "Approved": approved,
    "Number": number,
    "ArticleLink": article_link
})

const postInfoBody = (content, place, news_id)=> http.post("/protected/info_bodies", {
    "Content": content,
    "Place": place,
    "Article_ID": news_id
})

const updateInfoBody = (body_id, content, place, news_id)=> http.put("/protected/info_bodies/"+body_id, {
    "Content": content,
    "Place": place,
    "Article_ID": news_id
})

const publishArticle = (content_id) => http.post("/protected/publish/info_article/" + content_id)
const unPublishArticle = (content_id) => http.delete("/protected/publish/info_article/" + content_id)
const updatePublishArticle = (content_id) => http.put("/protected/publish/info_article/" + content_id)
const telegramPost = (news_id) => http.post("/protected/telegram_info/" + news_id)


export default {
    getInfoArticles,
    getInfoArticle,
    deleteInfoArticle,
    deleteInfoBody,
    postInfoArticle,
    updateInfoArticle,
    postInfoBody,
    updateInfoBody,
    updateStartingInfoArticle,
    postStartingInfoArticle,
    publishArticle,
    unPublishArticle,
    updatePublishArticle,
    telegramPost,
    getInfoOfferCards,
    getInfoOfferCard,

    update_info_offer_card,
    create_info_offer_card,
    delete_info_offer_card
}