
const LinkURL = (props) => {
    const linkURL = props.linkURL
    const updateURL = props.setLinkURL

    return (
        <div className="article-title-section">
            <div className="col-25 article-label">
                <label className="name-input-label">Button URL:</label>
            </div>
            <div className="col-60 article-input-box">
                <textarea className="name-input" id="title-input" value={linkURL}
                       onChange={(e) => updateURL(e.target.value)}/>
            </div>
        </div>
    )
}

export default LinkURL