import http from "./http";
import chapter from "../pages/Chapter";


const getChapters = () => http.get("/protected/cms_chapters")
const getChapter = (chapter_id) => http.get("/protected/cms_chapters/" + chapter_id)

const createChapter = (module, number, title, author, difficulty, video_url) => http.post("/protected/chapters", {
        "module": module,
        "number": number,
        "title": title,
        "author": author,
        "difficulty": difficulty,
        "video_url": video_url
    }
)

const updateChapter = (chapter_id, module, number, title, author, difficulty, approved, video_url) => http.put("/protected/chapters/" + chapter_id, {
    "module": module,
    "number": number,
    "title": title,
    "author": author,
    "difficulty": difficulty,
    "approved": approved,
    "video_url": video_url
})

const deleteChapter = (chapter_id) => http.delete("/protected/chapters/" + chapter_id)

const postChapterContent = (content, place, chapter_id) => http.post("/protected/chapter_content", {
    "content": content,
    "place": place,
    "chapter_id": chapter_id
})

const updateChapterContent = (chapter_content, content, place, chapter_id) => http.put("/protected/chapter_content/put/" + chapter_content, {
    "content": content,
    "place": place,
    "chapter_id": chapter_id
})

const deleteChapterContent = (chapter_content) => http.delete("/protected/chapter_content/del/" + chapter_content)

const getChaptersNames = () => http.get("/protected/get_chapters")

const createSectionAudio = (chapter_id, index, text_script) => http.post("/protected/narrate_text/" + chapter_id + "/" + index, {
    "Script": text_script
})

const publishArticle = (content_id) => http.post("/protected/publish/chapter/" + content_id)
const unPublishArticle = (content_id) => http.delete("/protected/publish/chapter/" + content_id)
const updatePublishArticle = (content_id) => http.put("/protected/publish/chapter/" + content_id)

const getHiddenChapterStatus = (content_id) => http.get("/protected/hide_chapters/" + content_id)
const putHiddenChapterStatus = (content_id, _hidden_status) => http.put("/protected/hide_chapters/" + content_id + "/" + _hidden_status)

export default {
    getChapters,
    getChapter,
    createChapter,
    updateChapter,
    deleteChapter,
    postChapterContent,
    updateChapterContent,
    deleteChapterContent,
    getChaptersNames,
    createSectionAudio,
    publishArticle,
    unPublishArticle,
    updatePublishArticle,
    getHiddenChapterStatus,
    putHiddenChapterStatus
}