import React from "react";
import SideNav from "./SideNav";
import {Link, Navigate, Route, Routes} from "react-router-dom";

import InfoOfferCardPage from "../pages/InfoOfferCardPage";
import InfoOfferCards from "../pages/InfoOfferCards";

const Home = React.lazy(() => import("../pages/Home"));
const News = React.lazy(() => import("../pages/News"));
const Info = React.lazy(() => import("../pages/Info"));
const Encyclopedia = React.lazy(() => import("../pages/Encyclopedia"));
const Season = React.lazy(() => import("../pages/Season"));
const Category = React.lazy(() => import("../pages/Category"));
const Module = React.lazy(() => import("../pages/Module"));
const Chapter = React.lazy(() => import("../pages/Chapter"));
const Quizzes = React.lazy(() => import("../pages/Quizzes"));
const EncyclopediaPage = React.lazy(() => import("../pages/EncyclopediaPage"));
const ContentAreas = React.lazy(() => import("../pages/CategoryContentAreas"));
const NewsArticle = React.lazy(() => import("../pages/NewsArticle"));
const InfoArticle = React.lazy(() => import("../pages/InfoArticle"));
const SeasonPage = React.lazy( () => import("../pages/SeasonPage"));
const CategoryPage = React.lazy( () => import("../pages/CategoryPage"));
const ModulePage = React.lazy( () => import("../pages/ModulePage"));
const ChapterPage = React.lazy( () => import("../pages/ChapterPage"));
const QuizzesPage = React.lazy( () => import("../pages/QuizzesPage"));
const AffiliateLinks = React.lazy( () => import("../pages/AffiliateLinks"));
const AffiliateLinksPage = React.lazy( () => import("../pages/AffiliateLinkPage"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const Deals = React.lazy(() => import("../pages/Deals"));
const DealsPage = React.lazy(() => import("../pages/DealsPage"));

const Rewards = React.lazy(() => import("../pages/Rewards"));
const RewardsPage = React.lazy(() => import("../pages/RewardsPage"));
const Users = React.lazy(() => import("../pages/Users"));
const UserPage= React.lazy(() => import("../pages/UserPage"));
const ManageRewards = React.lazy(()  => import("../pages/ManageRewards"))
const ManageRewardsPage = React.lazy(() => import("../pages/ManageRewardsPage"))

const AppLayout = () => {

    return (
        <>
            <SideNav />
            <div className="sidenav-nav">
                <Routes>
                    <Route exact path="/" element={<Navigate to="/home"/>}/>
                        <Route path="/home" element={
                            <React.Suspense fallback={<>...</>}>
                                <Home/>
                            </React.Suspense>
                        }/>
                    <Route path="/news" element={
                            <React.Suspense fallback={<>...</>}>
                                <News/>
                            </React.Suspense>
                        }/>
                    <Route path="/info" element={
                            <React.Suspense fallback={<>...</>}>
                                <Info/>
                            </React.Suspense>
                        }/>
                    <Route path="/affiliate" element={
                            <React.Suspense fallback={<>...</>}>
                                <AffiliateLinks />
                            </React.Suspense>
                        }/>
                    <Route path="/info-offer-cards" element={
                        <React.Suspense fallback={<>...</>}>
                            <InfoOfferCards />
                        </React.Suspense>
                    }/>
                    <Route path="/encyclopedia" element={
                        <React.Suspense fallback={<>...</>}>
                            <Encyclopedia/>
                        </React.Suspense>
                    }/>
                    <Route path="/season" element={
                            <React.Suspense fallback={<>...</>}>
                                <Season/>
                            </React.Suspense>
                        }/>
                    <Route path="/category" element={
                            <React.Suspense fallback={<>...</>}>
                                <Category/>
                            </React.Suspense>
                        }/>
                    <Route path="/module" element={
                            <React.Suspense fallback={<>...</>}>
                                <Module/>
                            </React.Suspense>
                        }/>
                    <Route path="/chapter" element={
                            <React.Suspense fallback={<>...</>}>
                                <Chapter/>
                            </React.Suspense>
                        }/>
                    <Route path="/quizzes" element={
                            <React.Suspense fallback={<>...</>}>
                                <Quizzes/>
                            </React.Suspense>
                        }/>
                    <Route path="/content-areas" element={
                            <React.Suspense fallback={<>...</>}>
                                <ContentAreas/>
                            </React.Suspense>
                        }/>
                    <Route path="/news_article">
                        <Route path=":storyId" element={
                            <React.Suspense fallback={<>...</>}>
                                <NewsArticle />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/encyclopedia_article">
                        <Route path=":storyId" element={
                            <React.Suspense fallback={<>...</>}>
                                <EncyclopediaPage />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/info_article">
                        <Route path=":storyId" element={
                            <React.Suspense fallback={<>...</>}>
                                <InfoArticle />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/season">
                        <Route path=":storyId" element={
                            <React.Suspense fallback={<>...</>}>
                                <SeasonPage />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/category">
                        <Route path=":storyId" element={
                            <React.Suspense fallback={<>...</>}>
                                <CategoryPage />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/module">
                        <Route path=":storyId" element={
                            <React.Suspense fallback={<>...</>}>
                                <ModulePage />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/chapter">
                        <Route path=":storyId" element={
                            <React.Suspense fallback={<>...</>}>
                                <ChapterPage />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/affiliate-link">
                        <Route path=":storyId" element={
                            <React.Suspense fallback={<>...</>}>
                                <AffiliateLinksPage />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/info-offer-card">
                        <Route path=":storyId" element={
                            <React.Suspense fallback={<>...</>}>
                                <InfoOfferCardPage />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/quizzes">
                        <Route path=":storyId" element={
                            <React.Suspense fallback={<>...</>}>
                                <QuizzesPage />
                            </React.Suspense>
                        }/>
                    </Route>
                    <Route path="/dashboard" element={
                        <React.Suspense fallback={<>...</>}>
                            <Dashboard/>
                        </React.Suspense>
                    }/>
                    <Route path="/deals" element={
                        <React.Suspense fallback={<>...</>}>
                            <Deals/>
                        </React.Suspense>
                    }/>
                    <Route path="/deals">
                        <Route path=":dealId" element={
                            <React.Suspense fallback={<>...</>}>
                                <DealsPage/>
                            </React.Suspense>
                        }/>
                    </Route>

                    <Route path="/rewards" element={
                        <React.Suspense fallback={<>...</>}>
                            <Rewards />
                        </React.Suspense>
                    }/>
                    <Route path="/rewards">
                        <Route path=":reward_id" element={
                            <React.Suspense fallback={<>...</>}>
                                <RewardsPage />
                            </React.Suspense>
                        }/>
                    </Route>

                    <Route path="/users" element={
                        <React.Suspense fallback={<>...</>}>
                            <Users />
                        </React.Suspense>
                    }/>
                    <Route path="/user">
                        <Route path=":user_email" element={
                            <React.Suspense fallback={<>...</>}>
                                <UserPage />
                            </React.Suspense>
                        }/>
                    </Route>

                    <Route path="/manage-rewards" element={
                        <React.Suspense fallback={<>...</>}>
                            <ManageRewards />
                        </React.Suspense>
                    }/>
                    <Route path="/manage-rewards">
                        <Route path=":reward_id" element={
                            <React.Suspense fallback={<>...</>}>
                                <ManageRewardsPage />
                            </React.Suspense>
                        }/>
                    </Route>
                </Routes>
        </div>
        </>
    )
}

export default AppLayout;