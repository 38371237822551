import React from "react";
import {Link} from "react-router-dom";
import cse_logo_light from "../assets/cse_logo_light.svg";

const sideNav = () =>{

    return (
        <>
            <div className="side-nav-container">
                <div className="side-nav-image">
                    <Link to="/home">
                        <img src={cse_logo_light} className="cse-logo"/>
                    </Link>
                </div>
                <div className="sidenav">
                    <Link to="/home" className="row sidenav-link">
                        Home
                    </Link>
                    <Link to="/dashboard" className="row sidenav-link">
                        Dashboard
                    </Link>
                    <Link to="/news" className="row sidenav-link">
                        News
                    </Link>
                    <Link to="/info" className="row sidenav-link">
                        Info
                    </Link>
                    <Link to="/encyclopedia" className="row sidenav-link">
                        Encyclopedia
                    </Link>
                    <Link to="/affiliate" className="row sidenav-link">
                        Affiliate Links
                    </Link>
                    <Link to="/info-offer-cards" className="row sidenav-link">
                        Info Offer Cards
                    </Link>
                    <Link to="/deals" className="row sidenav-link">
                        Deals
                    </Link>

                    <div className="learn-content-sidenav-label">
                        Referrals
                    </div>
                    <div className="referrals-content">
                        <Link to="/rewards" className="row sidenav-link">
                            Rewards
                        </Link>
                        <Link to="/users" className="row sidenav-link">
                            Users
                        </Link>
                        <Link to="/manage-rewards" className="now sidenav-link">
                            Manage User Rewards
                        </Link>
                    </div>

                    <div className="learn-content-sidenav-label">
                        Learn
                    </div>
                    <div className="learn-content">
                        <Link to="/season" className="row sidenav-link">
                            Season
                        </Link>
                        <Link to="/category" className="row sidenav-link">
                            Category
                        </Link>
                        <Link to="/module" className="row sidenav-link">
                            Module
                        </Link>
                        <Link to="/chapter" className="row sidenav-link">
                            Chapter
                        </Link>
                        <Link to="/quizzes" className="row sidenav-link">
                            Quizzes
                        </Link>
                    </div>

                    <div className="categories-content-sidenav-label">
                        Classification Categories
                    </div>
                    <div className="categories-content">
                        <Link to="/content-areas" className="row sidenav-link">
                            Category Content Areas
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default sideNav;